import React, { Component } from "react";
// import Pagination from "@material-ui/lab/Pagination";
import DeleteIcon from "../icon/DeleteIcon";
import EditIcon from "../icon/EditIcon";
import Loading from "./common/Loading";
import NoContent from "../icon/NoContent";
import "../style/site.scss";
import * as GetSiteListAPI from "../apis/GetSiteListAPI";
import AddSite from "../icon/AddSite";
import * as DeleteSiteAPI from "../apis/DeleteSiteAPI";
import cookie from "react-cookies";
import Config from "../icon/Config";
import OrderIcon from "../icon/OrderIcon";
import SearchIcon from "../icon/SearchIcon";
import { Pagination } from "antd";

class Site extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      page: 1,
      per_page: 10,
      count: 0,
      siteList: [],
      userid: 0,
      search: "",
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.getSiteList = this.getSiteList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.routeAddSite = this.routeAddSite.bind(this);
    this.delete = this.delete.bind(this);
    this.routeAddConfig = this.routeAddConfig.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
    this.clickToSearch = this.clickToSearch.bind(this);
  }

  componentDidMount() {
    let param = cookie.load("token");
    let { userid } = this.state;
    if (param) {
      console.log(param);
      if (parseInt(param.user.type) !== 2) {
        userid = parseInt(param.user.id);
        this.setState({ userid });
      }
    }
    this.getSiteList(this.state.page, this.state.per_page, userid);
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  getSiteList(page, per_page, id, url) {
    this.toggleShow();
    GetSiteListAPI.getSiteList(page, per_page, id, url)
      .then((res) => {
        this.toggleShow();
        var data = res.data.data;
        if (data) {
          var total = data.total;
          let count = Math.ceil(total / per_page);
          this.setState({
            siteList: data.list,
            count,
          });
        }
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  handlePageChange(e, page, pageSize) {
    // 确保 page 和 pageSize 是数字
    const currentPage = parseInt(page, 10);
    const currentPerPage = parseInt(pageSize, 10);

    if (isNaN(currentPage) || isNaN(currentPerPage)) {
      console.error("Invalid page or pageSize");
      return;
    }
    this.setState({ page: currentPage, per_page: currentPerPage }, () => {
      this.getSiteList(
        currentPage,
        currentPerPage,
        this.state.userid,
        this.state.search
      );
    });
  }

  routeAddSite(id, userid) {
    const { history } = this.props;
    if (id) {
      history.push(`/AddSite?id=${id}&userid=${userid}`);
      return;
    }
    history.push(`/AddSite?userid=${userid}`);
  }

  routeAddConfig(url) {
    const { history } = this.props;
    history.push(`/AddConfig/${url}`);
  }

  changeSearch(e) {
    this.setState({ search: e.target.value });
  }

  clickToSearch() {
    this.getSiteList(
      1,
      this.state.per_page,
      this.state.userid,
      this.state.search
    );
  }

  delete(id) {
    this.toggleShow();
    DeleteSiteAPI.deleteSite(id)
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;
        if (code === 1) {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "删除站点成功",
            })
          );
          this.getSiteList(this.state.page, this.state.per_page);
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  render() {
    const { count, page, siteList, userid, search } = this.state;
    console.log(userid);
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="site-page">
          <div className="toolbar">
            <div className="search">
              <input
                type={search || ""}
                placeholder="site url ..."
                onChange={(e) => this.changeSearch(e)}
                onKeyDown={(e) => e.key === "Enter" && this.clickToSearch()}
              />
              <SearchIcon
                size={25}
                color="#fff"
                className="icon"
                onClick={this.clickToSearch}
              />
            </div>
            {userid === 0 ? (
              <AddSite
                size={35}
                color="#fff"
                className="icon"
                onClick={() => this.routeAddSite("", userid)}
              />
            ) : (
              ""
            )}
          </div>
          <div className="head">
            <div className="left">
              <div className="site">SITE</div>
              <div className="xing">NAME</div>
              <div className="category">CATEGORY</div>
              <div className="state">STATE</div>
              <div className="time">TIME</div>
            </div>
            {/* {userid === 0 ? <div className="tool">OPERATION</div> : ""} */}
            <div className="tool">OPERATION</div>
          </div>
          <div className="body">
            {siteList.length > 0 ? (
              <React.Fragment>
                {siteList.map((item, index) => {
                  return (
                    <div className="li" key={index}>
                      <div className="left">
                        <div className="site">{item.url}</div>
                        <div className="xing">{item.xingming}</div>
                        <div className="category">{item.category}</div>
                        <div
                          className={`state ${item.status > 0 ? "on" : "off"}`}
                        >
                          {item.status > 0 ? "ON" : "OFF"}
                        </div>
                        <div className="time">{item.createtime}</div>
                      </div>
                      <div className="tool">
                        <React.Fragment>
                          <EditIcon
                            size={30}
                            className="icon"
                            color="#285db1"
                            onClick={() =>
                              this.routeAddSite(item.id, item.userid)
                            }
                          />
                          <Config
                            size={30}
                            className="icon"
                            color="#285db1"
                            onClick={() => this.routeAddConfig(item.url)}
                          />
                          {/* <OrderIcon
                            size={30}
                            className="icon"
                            color="#285db1"
                          /> */}
                          {userid === 0 ? (
                            <DeleteIcon
                              size={25}
                              className="icon"
                              color="#285db1"
                              onClick={() => this.delete(item.id)}
                            />
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              <div className="no-content">
                <NoContent size={200} />
              </div>
            )}
          </div>
        </div>
        <div className="footer">
        <Pagination
            size="small"
            total={this.state.count * this.state.per_page}
            // count={count}
            pageSize={this.state.per_page} 
            current={this.state.page} 
            onChange={(page, pageSize) =>
              this.handlePageChange(null, page, pageSize)
            }
            showQuickJumper
            variant="outlined"
            color="standard"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Site;
