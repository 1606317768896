import React, { Component } from "react";
import "../style/cost.scss";
import AddCost from "../icon/AddCost";
import * as GetCostListAPI from "../apis/GetCostListAPI";
// import Pagination from "@material-ui/lab/Pagination";
import DeleteIcon from "../icon/DeleteIcon";
import EditIcon from "../icon/EditIcon";
import Loading from "./common/Loading";
import NoContent from "../icon/NoContent";
import cookie from "react-cookies";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import moment from "moment";
import * as DeleteCostAPI from "../apis/DeleteCostAPI";
import SearchIcon from "../icon/SearchIcon";
import Select from "react-select";
import * as GetSiteListAPI from "../apis/GetSiteListAPI";

import { Pagination } from "antd";

class Cost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      page: 1,
      per_page: 10,
      count: 0,
      costList: [],
      userid: 0,
      siteList: [],
      site: {
        value: "",
        label: "Please choose site ...",
      },
      search: "",
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.getCostList = this.getCostList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.routeAddCost = this.routeAddCost.bind(this);
    this.delete = this.delete.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.getSiteList = this.getSiteList.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
    this.clickToSearch = this.clickToSearch.bind(this);
  }

  componentDidMount() {
    let param = cookie.load("token");
    let { userid } = this.state;
    if (param) {
      console.log(param);
      if (param.user.type !== 2) {
        userid = param.user.id;
        this.setState({ userid });
      }
    }
    this.getSiteList(userid);
    this.getCostList(this.state.page, this.state.per_page, userid);
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  getCostList(page, per_page, userid, url,search) {
    this.toggleShow();
    GetCostListAPI.getCostList(page, per_page, userid, url,search)
      .then((res) => {
        this.toggleShow();
        var data = res.data.data;
        if (data) {
          var total = data.total;
          let count = Math.ceil(total / per_page);
          data.list = data.list.map((item) => {
            item.consumptiontime = moment(
              new Date(item.consumptiontime * 1000)
            ).format("YYYY-MM-DD HH:mm:ss");
            return item;
          });

          console.warn(data.list);
          this.setState({
            costList: data.list,
            count,
          });
        }
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  handlePageChange(e, page, pageSize) {
    // 确保 page 和 pageSize 是数字
    const currentPage = parseInt(page, 10);
    const currentPerPage = parseInt(pageSize, 10);

    if (isNaN(currentPage) || isNaN(currentPerPage)) {
      console.error("Invalid page or pageSize");
      return;
    }
    this.setState({ page: currentPage, per_page: currentPerPage }, () => {
      this.getCostList(
        currentPage,
        currentPerPage,
        this.state.userid,
        this.state.site.label === "Please choose site ..."
          ? ""
          : this.state.site.label
      );
    });
  }

  routeAddCost(id, userid) {
    const { history } = this.props;
    if (id) {
      history.push(`/AddCost?id=${id}&userid=${userid}`);
      return;
    }
    history.push(`/AddCost?userid=${userid}`);
  }

  changeSearch(e) {
    this.setState({ search: e.target.value });
  }

  clickToSearch() {
    this.getCostList(
      1,
      this.state.per_page,
      this.state.userid,
      this.state.site.label === "Please choose site ..."
        ? ""
        : this.state.site.label,
      this.state.search
    );
  }

  delete(id) {
    this.toggleShow();
    DeleteCostAPI.deleteCost(id)
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;
        if (code === 1) {
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: "删除成功",
            })
          );
          this.getCostList(
            this.state.page,
            this.state.per_page,
            this.state.userid
          );
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  getSiteList(userid) {
    let siteList = [];
    GetSiteListAPI.getSiteListNoPage(userid).then((res) => {
      var data = res.data.data;
      if (data) {
        siteList = data.list.map((item) => {
          return (item = {
            value: item.id,
            label: item.url,
          });
        });
        this.setState({ siteList: siteList });
      }
    });
  }

  selectChange(e, type) {
    switch (type) {
      case "site":
        this.setState({ site: e }, () => {
          this.getCostList(
            this.state.page,
            this.state.per_page,
            this.state.userid,
            e.label
          );
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { count, page, costList, userid, site, siteList, search } =
      this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="cost-page">
          <div className="toolbar">
            <Select
              className="site-select"
              placeholder="Please choose site..."
              options={siteList}
              value={site || ""}
              onChange={(e) => this.selectChange(e, "site")}
            />

            <div className="right">
              <div className="search">
                <input
                  type={search || ""}
                  placeholder="name ..."
                  onChange={(e) => this.changeSearch(e)}
                  onKeyDown={(e) => e.key === "Enter" && this.clickToSearch()}
                />
                <SearchIcon
                  size={25}
                  color="#fff"
                  className="icon"
                  onClick={this.clickToSearch}
                />
              </div>
              <AddCost
                size={35}
                color="#fff"
                className="icon"
                onClick={() => this.routeAddCost("", userid)}
              />
            </div>
          </div>
          <div className="head">
            <div className="left">
              <div className="site">SITE</div>
              <div className="price">PRICE</div>
              <div className="xing">NAME</div>
              <div className="time">TIME</div>
            </div>
            <div className="tool">OPERATION</div>
          </div>
          <div className="body">
            {costList.length > 0 ? (
              <React.Fragment>
                {costList.map((item, index) => {
                  return (
                    <div className="li" key={index}>
                      <div className="left">
                        <div className="site">{item.url}</div>
                        <div className="price">¥{item.payexpenses}</div>
                        <div className="xing">{item.xingming}</div>
                        <div className="time">{item.consumptiontime}</div>
                      </div>
                      <div className="tool">
                        <EditIcon
                          size={25}
                          className="icon"
                          color="#285db1"
                          onClick={() =>
                            this.routeAddCost(item.id, item.userid)
                          }
                        />
                        <DeleteIcon
                          size={25}
                          className="icon"
                          color="#285db1"
                          onClick={() => this.delete(item.id)}
                        />
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              <div className="no-content">
                <NoContent size={200} />
              </div>
            )}
          </div>
        </div>
        <div className="footer">
          <Pagination
            size="small"
            total={this.state.count * this.state.per_page}
            pageSize={this.state.per_page}
            current={this.state.page}
            onChange={(page, pageSize) =>
              this.handlePageChange(null, page, pageSize)
            }
            showQuickJumper
            variant="outlined"
            color="standard"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Cost;
