import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  BrowserRouter,
} from "react-router-dom";

import Popup from "./components/common/Popup";
import Loading from "./components/common/Loading";
import queryString from "query-string";
import Login from "./components/Login";
import Entrance from "./components/Entrance";
import "./utils/OrderUtil";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BrowserRouter>
        <React.Fragment>
          <Popup {...this.props} />
          <Switch>
            <Route path="/Login" component={Login}></Route>
            <Route path="/" component={Entrance}></Route>
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}
export default App;
