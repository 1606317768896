import React, { Component } from "react";
// import Pagination from "@material-ui/lab/Pagination";
import DeleteIcon from "../icon/DeleteIcon";
import EditIcon from "../icon/EditIcon";
import Loading from "./common/Loading";
import NoContent from "../icon/NoContent";
import "../style/order.scss";
import * as GetSiteListAPI from "../apis/GetSiteListAPI";
import cookie from "react-cookies";
import OrderDetail from "../icon/OrderDetail";
import * as GetOrderListAPI from "../apis/GetOrderListAPI";
import PaySuucess from "../icon/PaySuccess";
import PayError from "../icon/PayError";
import moment from "moment";
import True from "../icon/True";
import False from "../icon/False";
import Select from "react-select";
import Email from "../icon/SendEmail";
import SearchIcon from "../icon/SearchIcon";
import SubmitIcon from "../icon/SubmitIcon";
import CloseIcon from "../icon/CloseIcon";
import SeeIcon from "../icon/SeeIcon";
import NoSeeIcon from "../icon/NoSeeIcon";
import Reload from "../icon/Reload";
import * as GetFileDetailAPI from "../apis/GetFileDetailAPI";
import * as SendEmailAPI from "../apis/SendEmailAPI";

import { Pagination } from "antd";

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      page: 1,
      per_page: 10,
      count: 0,
      orderList: [],
      userid: 0,
      siteList: [],
      site: {
        value: "",
        label: "Please choose site ...",
      },
      isClick: false,
      states: "",
      searchValue: "",
      name: "",
      emailFileArr: [],
      emailSelect: {},
      emailMask: false,
      toEmailUser: "",
      checkFile: true,
      locating: "",
      replace: "",
      content: "",
      subjectLocating: "",
      subjectReplace: "",
      search: "",
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.getOrderList = this.getOrderList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.routeAddSite = this.routeAddSite.bind(this);
    this.routeAddConfig = this.routeAddConfig.bind(this);
    this.clickTransformImage = this.clickTransformImage.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.getSiteList = this.getSiteList.bind(this);
    this.clickToMove = this.clickToMove.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
    this.clickToSearch = this.clickToSearch.bind(this);
  }

  componentDidMount() {
    let param = cookie.load("token");
    let userid = 0;
    if (param) {
      console.log(param);
      if (param.user.type !== 2) {
        userid = param.user.id;
        this.setState({ userid, usertype: param.user.type });
      }
    }
    this.getSiteList(userid);
    this.getOrderList(this.state.page, this.state.per_page, userid);
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  getOrderList(page, per_page, userid, url, states, search) {
    this.toggleShow();
    GetOrderListAPI.getOrderList(page, per_page, userid, url, states, search)
      .then((res) => {
        this.toggleShow();
        var data = res.data.data;
        if (data) {
          var total = data.total;
          let count = Math.ceil(total / per_page);
          data.list.map((item, index) => {
            item.isOpen = [false, false, false];
          });
          this.setState({
            orderList: data.list,
            count,
          });
        }
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  handlePageChange(e, page, pageSize) {
    // 确保 page 和 pageSize 是数字
    const currentPage = parseInt(page, 10);
    const currentPerPage = parseInt(pageSize, 10);

    if (isNaN(currentPage) || isNaN(currentPerPage)) {
      console.error("Invalid page or pageSize");
      return;
    }
    this.setState({ page: currentPage, per_page: currentPerPage }, () => {
      this.getOrderList(
        currentPage,
        currentPerPage,
        this.state.userid,
        this.state.site.label === "Please choose site ..."
          ? ""
          : this.state.site.label,
        this.state.states,
        this.state.search
      );
    });
  }

  clickToMove() {
    let { isClick, states } = this.state;
    states = isClick ? "" : 1;
    this.setState({ isClick: !this.state.isClick, states }, () => {
      this.getOrderList(
        this.state.page,
        this.state.per_page,
        this.state.userid,
        this.state.site.label === "Please choose site ..."
          ? ""
          : this.state.site.label,
        this.state.states
      );
    });
  }

  routeAddSite(id) {
    const { history } = this.props;
    if (id) {
      history.push(`/AddSite/${id}`);
      return;
    }
    history.push(`/AddSite`);
  }

  routeAddConfig(url) {
    const { history } = this.props;
    history.push(`/AddConfig/${url}`);
  }

  clickTransformImage(index, InIndex) {
    let { orderList } = this.state;
    orderList[index].isOpen[InIndex] = !orderList[index].isOpen[InIndex];
    this.setState({ orderList });
  }

  getSiteList(userid) {
    let siteList = [];
    GetSiteListAPI.getSiteListNoPage(userid).then((res) => {
      var data = res.data.data;
      if (data) {
        siteList = data.list.map((item) => {
          return (item = {
            value: item.id,
            label: item.url,
          });
        });
        this.setState({ siteList: siteList });
      }
    });
  }

  selectChange(e, type) {
    switch (type) {
      case "site":
        this.setState({ site: e }, () => {
          this.getOrderList(1, this.state.per_page, this.state.userid, e.label);
        });
        break;
      default:
        break;
    }
  }

  changeSearch(e) {
    this.setState({ search: e.target.value });
  }

  clickToSearch() {
    this.getOrderList(
      1,
      this.state.per_page,
      this.state.userid,
      this.state.site.label === "Please choose site ..."
        ? ""
        : this.state.site.label,
      this.state.states,
      this.state.search
    );
  }

  inputChange(e, type) {
    switch (type) {
      case "select":
        this.setState({ stateValue: e });
        this.setState({ status: e.value }, () => {
          this.getFileList();
        });
        break;
      case "search":
        this.setState({ name: e.target.value });
        break;

      case "email":
        this.setState({ toEmailUser: e.target.value });
        break;
      case "locating":
        this.setState({ locating: e.target.value });
        break;
      case "replace":
        this.setState({ replace: e.target.value });
        break;
      case "subjectLocating":
        this.setState({ subjectLocating: e.target.value });
        break;
      case "subjectReplace":
        this.setState({ subjectReplace: e.target.value });
        break;

      default:
        break;
    }
  }

  sendEmail(item) {
    console.log(item);
    let emailTemplateId = item.emailTemplateId;
    let toEmailUser = item.data.email;
    let replace = `{${item.data.firstName + item.data.lastName}},{${item.url}}`;
    let subjectreplace = `{${item.data.firstName + item.data.lastName}}`;
    let username = item.username;
    if (username === 'chen') {
      if (!item.data.userSelfPictureOrVideo || item.data.userSelfPictureOrVideo === '') {
        // 只追Tesla证件
        emailTemplateId = '36';
      }
    }
    this.toggleShow();
    GetFileDetailAPI.getFileDetail(emailTemplateId)
      .then((res) => {
        var data = res.data.data;
        if (data) {
          console.log(data);
          let locating = data.locating;
          let subjectlocating = data.subjectlocating;
          SendEmailAPI.sendEmail(
            emailTemplateId,
            toEmailUser,
            locating,
            replace,
            subjectlocating,
            subjectreplace,
            username
          )
            .then((res) => {
              this.toggleShow();
              var code = res.data.code;
              var msg = res.data.msg;
              if (code === 200) {
                window.dispatchEvent(
                  new CustomEvent("showAlert", {
                    detail: msg,
                  })
                );
                this.setState({
                  emailMask: !this.state.emailMask,
                  replace: "",
                });
              } else {
                window.dispatchEvent(
                  new CustomEvent("showAlert", {
                    detail: msg,
                  })
                );
              }
            })
            .catch(() => {
              this.toggleShow();
            });
        }
      })
      .catch(() => {
        this.toggleShow();
      });
  }

  render() {
    const {
      count,
      page,
      orderList,
      userid,
      site,
      siteList,
      isClick,
      statusArr,
      emailFileArr,
      emailSelect,
      emailMask,
      content,
      checkFile,
      locating,
      replace,
      subjectLocating,
      subjectReplace,
      search,
    } = this.state;
    let param = cookie.load("token");
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="order-page">
          <div className="toolbar">
            <Select
              className="site-select"
              placeholder="Please choose site..."
              options={siteList}
              value={site || ""}
              onChange={(e) => this.selectChange(e, "site")}
            />
            <div className="right">
              <div className="search">
                <input
                  type={search || ""}
                  placeholder="email or name ..."
                  onChange={(e) => this.changeSearch(e)}
                  onKeyDown={(e) => e.key === "Enter" && this.clickToSearch()}
                />
                <SearchIcon
                  size={25}
                  color="#fff"
                  className="icon"
                  onClick={this.clickToSearch}
                />
              </div>
              <div className="statuBar">
                <p className="btn-p" onClick={this.clickToMove}>
                  ON
                </p>
                <p className="btn-p" onClick={this.clickToMove}>
                  OFF
                </p>
                <div className={`btn ${isClick ? "move" : ""}`}></div>
              </div>
            </div>
          </div>
          <div className="head">
            <div className="left">
              <div className="status">状态</div>
              <div className="ad">AD</div>
              <div className="url">url</div>
              <div className="email">邮箱</div>
              <div className="name">用户名</div>
              <div className="back">证件背面</div>
              <div className="front">证件正面</div>
              <div className="video">视频</div>
              {/* {param.user.type === 2 ? (
                <React.Fragment>
                  <div className="cardCvc">Cvc</div>
                  <div className="cardType">Type</div>
                  <div className="cardExpiry">Expiry</div>
                  <div className="cardNum">Number</div>
                </React.Fragment>
              ) : (
                ""
              )} */}
              {/* <div className="shareNum">分享次数</div> */}
              <div className="shareSuccess">分享成功</div>
              <div className="time">time</div>
            </div>
            <div className="tool">Operation</div>
          </div>
          <div className="body">
            {orderList.length > 0 ? (
              <React.Fragment>
                {orderList.map((item, index) => {
                  return (
                    <div className="li" key={index}>
                      <div className="left">
                        <div className="status">
                          {item.states > 0 ? (
                            <PaySuucess size={30} color="#196d1f" />
                          ) : (
                            <PayError size={28} color="#d81e06" />
                          )}
                        </div>
                        <div className="ad">{item.data.ad}</div>
                        <div className="url">{item.url}</div>
                        <div className="email">{item.data.email}</div>
                        <div className="name">
                          {item.data.firstName} {item.data.lastName}
                        </div>
                        <div className="back">
                          <div
                            className={`img-box ${
                              item.isOpen[0] ? "isOpen" : ""
                            }`}
                            onClick={() => this.clickTransformImage(index, 0)}
                          >
                            {item.data.documentBack && (
                              <img src={item.data.documentBack} alt="" />
                            )}
                          </div>
                        </div>
                        <div className="front">
                          <div
                            className={`img-box ${
                              item.isOpen[1] ? "isOpen" : ""
                            }`}
                            onClick={() => this.clickTransformImage(index, 1)}
                          >
                            {item.data.documentFront && (
                              <img src={item.data.documentFront} alt="" />
                            )}
                          </div>
                        </div>
                        <div className="video">
                          <div
                            className={`img-box ${
                              item.isOpen[2] ? "isOpen" : ""
                            }`}
                            onClick={() => this.clickTransformImage(index, 2)}
                          >
                            {item.data.userSelfPictureOrVideo &&
                              (/\.mp4(\?|$)/i.test(
                                item.data.userSelfPictureOrVideo
                              ) ? (
                                <video
                                  src={item.data.userSelfPictureOrVideo}
                                  muted={true}
                                  autoPlay={true}
                                ></video>
                              ) : (
                                <img src={item.data.userSelfPictureOrVideo} />
                              ))}
                          </div>
                        </div>
                        {/* {param.user.type === 2 ? (
                          <React.Fragment>
                            <div className="cardCvc">
                              {item.data.cardCvc && item.data.cardCvc}
                            </div>
                            <div className="cardType">
                              {item.data.cardType && item.data.cardType}
                            </div>
                            <div className="cardExpiry">
                              {item.data.cardExpiry && item.data.cardExpiry}
                            </div>
                            <div className="cardNum">
                              {item.data.cardNumber && item.data.cardNumber}
                            </div>
                          </React.Fragment>
                        ) : (
                          ""
                        )} */}
                        {/* <div className="shareNum">
                          {item.clickShareNum && item.clickShareNum}
                        </div> */}

                        <div className="shareSuccess">
                          {item.shareSuccess && item.shareSuccess === "1" ? (
                            <True size={16} />
                          ) : (
                            <False size={16} />
                          )}
                        </div>
                        <div className="time">
                          {moment(item.updatetime * 1000).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        </div>
                      </div>
                      <div className="tool">
                        <Email
                          size={25}
                          className="icon"
                          color="#285db1"
                          onClick={() => this.sendEmail(item)}
                        />
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            ) : (
              <div className="no-content">
                <NoContent size={200} />
              </div>
            )}
          </div>
        </div>
        <div className="footer">
          <Pagination
            size="small"
            total={this.state.count * this.state.per_page}
            pageSize={this.state.per_page}
            current={this.state.page}
            onChange={(page, pageSize) =>
              this.handlePageChange(null, page, pageSize)
            }
            showQuickJumper
            variant="outlined"
            color="standard"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Order;
